import React, { ComponentProps, useCallback, useState } from "react";
import { Graphics } from "@inlet/react-pixi";
import { flatten } from "ramda";

export type PolygonProps = {
  path: [x: number, y: number][];
};

type Draw = NonNullable<ComponentProps<typeof Graphics>["draw"]>;

const Polygon: React.FC<PolygonProps> = ({ path }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const draw = useCallback<Draw>(
    (g) => {
      const flattenedPath = flatten(path);
      g.clear();
      g.beginFill(0xffcc00, 0.5);
      g.lineStyle(isHovered ? 4 : 1, isHovered ? 0x9900ff : 0xffffff, 1); // TODO: Needs to be zoom aware
      g.drawPolygon(flattenedPath);
    },
    [path, isHovered]
  );

  const onPointerOver = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onPointerOut = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <Graphics
      draw={draw}
      interactive={true}
      pointerout={onPointerOut}
      pointerover={onPointerOver}
    />
  );
};

export default Polygon;
