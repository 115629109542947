import tw from "twin.macro";
import React from "react";
import { Stage } from "@inlet/react-pixi";
import useDimensions from "react-cool-dimensions";
import Viewport from "../Viewport";
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from "recoil";

const Container = tw.main`
bg-red-200
flex-grow
h-screen
`;

const DrawingCanvas: React.FC = ({ children }) => {
  const { ref, width, height } = useDimensions();
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

  return (
    <Container ref={ref}>
      <Stage
        options={{
          backgroundColor: 0x37425a,
          antialias: true,
          autoDensity: true,
        }}
        width={width}
        height={height}
      >
        <Viewport>
          <RecoilBridge>{children}</RecoilBridge>
        </Viewport>
      </Stage>
    </Container>
  );
};

export default DrawingCanvas;
