import React from "react";
import { PixiComponent, useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from "pixi-viewport";
import type { Application, DisplayObject } from "pixi.js";

type ViewportInnerComponentProps = {
  app: Application;
};

const WORLD_WIDTH = 1000;
const WORLD_HEIGHT = 1000;

const ViewportInnerComponent = PixiComponent<
  ViewportInnerComponentProps,
  DisplayObject
>("Viewport", {
  create: ({ app }) => {
    const viewport = new PixiViewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: WORLD_WIDTH,
      worldHeight: WORLD_HEIGHT,
      ticker: app.ticker,
      interaction: app.renderer.plugins.interaction,
    });

    viewport
      .drag({
        pressDrag: false,
      })
      .pinch()
      .decelerate();

    return viewport;
  },
});

const Viewport: React.FC = ({ children }) => {
  const app = useApp();
  return <ViewportInnerComponent app={app}>{children}</ViewportInnerComponent>;
};

export default Viewport;
