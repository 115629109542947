import React from "react";
import { Global, css } from "@emotion/react";
import DrawingCanvas from "../DrawingCanvas";
import tw from "twin.macro";
import Panel from "../Panel";
import Polygon from "../Polygon";

const Container = tw.div`
  flex
`;

const App: React.FC = () => {
  const graphics = new Array(10).fill(0).map((_, i) => {
    return (
      <Polygon
        key={i}
        path={[
          [100, 100],
          [200, 200],
          [300, 500],
        ]}
      />
    );
  });

  return (
    <>
      <Container>
        <Panel>Layers</Panel>
        <DrawingCanvas>{graphics}</DrawingCanvas>
        <Panel>Settings</Panel>
      </Container>
      <Global
        styles={css`
          html,
          body {
            height: 100%;
            margin: 0;
            overflow: hidden;
          }
        `}
      />
    </>
  );
};

export default App;
